import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/5rLwzCokqrg">
    <SEO title="Overcoming Failure - Crossing Rivers" />
  </Layout>
)

export default SermonPost
